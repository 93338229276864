import React, { useEffect, useRef } from "react"
import Layout from "../layouts"
import styled from "styled-components"
import Hero from "../components/Hero"
import { useIntersection } from "react-use"
import LetterAnimation from "../animations/LetterAnimation"
import { PageContainer } from "../styles/global"
import { pageMargins, remCalc } from "../styles/utils"
import { typography } from "../styles/variables"
import NextPage from "../components/NextPage"
import data from "../../lib/economic.json"
import { StyledItem } from "../components/List/Item"
import List from "../components/List"
import { addSvgListeners } from "../../lib/helperFunctions"
import useIsTablet from "../hooks/useIsTablet"
import useOnScreen from "../hooks/useOnScreen"

const StyledEconomic = styled.div`
  ${pageMargins}
  display: flex;
  flex-direction: column;

  position: relative;
  & .item {
    padding: 2rem 0;
  }

  & .economic_item-title {
    font-size: 2rem;
    line-height: ${remCalc(45)};
    font-family: ${typography.type.primary};
    @media only screen and (max-width: 640px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
  & .economic_impact-title {
    font-size: 1.25rem;
    font-family: ${typography.type.primary};
    font-weight: bold;
    letter-spacing: 0.1px;
    padding-bottom: 2rem;
    padding-top: 1rem;
    border-bottom: 1px solid #a6b6ba;
  }
`

const EconomicContribution = ({ location }) => {
  const isTablet = useIsTablet()

  useEffect(() => {
    addSvgListeners()
    document.querySelector("header").classList.add("header-black")
  }, [])

  return (
    <Layout location={location}>
      <Hero bg="transparent" economic location={location.pathname}>
        {isTablet ? (
          <h1 className="hero_title">Total economic contribution</h1>
        ) : (
          <LetterAnimation duration={0.7} delay={0.017} initDelay={0.2}>
            <h1 className="hero_title text-animated">
              <span className="line-inner">Total</span>
            </h1>
            <h1 className="hero_title text-animated">
              <span className="line-inner">economic</span>
            </h1>
            <h1 className="hero_title text-animated">
              <span className="line-inner">contribution</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
      <StyledEconomic>
        <List>
          <StyledItem className="item">
            <h3 className="economic_item-title">Asset</h3>
            <p>GVA</p>
          </StyledItem>
          <div className="grey_padding-element">
            <p>
              Methodology for GVA: Gross Value Added (GVA) is a metric for
              economic productivity and measures the contribution of a company,
              sector or place to an economy.
            </p>
          </div>
          {data.uk.map(item => (
            <StyledItem id={item.label} className="item economic-item">
              <p>{item.place}</p>
              <p>{item.amount}</p>
            </StyledItem>
          ))}
        </List>
        <List>
          <h4 className="economic_impact-title">Impact in London</h4>
          {data.london.map(item => (
            <StyledItem id={item.label} className="item economic-item">
              <p>{item.place}</p>
              <p>{item.amount}</p>
            </StyledItem>
          ))}
          <div className="grey_padding-element map_fade-out-elem">
            <p>Economic output (GVA) impacts have been estimated by planning and development consultancy Lichfields, drawing on Landsec databases, published research and national statistics. All figures are based on the 2020 calendar year.</p>
          </div>
        </List>
      </StyledEconomic>

      <div className="economic_bottom-section">
        <PageContainer>
          <NextPage
            colorKey="covid_19"
            page={{
              title: "Our response to Covid-19",
              path: "covid-19-response"
            }}
          />
        </PageContainer>
      </div>
    </Layout>
  )
}

export default EconomicContribution
