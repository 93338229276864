import React, { useEffect, useState } from "react"
// Hook

function useOnScreen(ref, rootMargin = "0px") {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState({})

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry)
        if (
          entry.isIntersecting &&
          !ref.current.classList.contains("animate-in")
        ) {
          ref.current.classList.add("animate-in")
          if (ref.current.dataset.delay) {
            ref.current.transitionDelay = ref.current.dataset.delay
          }
          if (ref.current.dataset.duration) {
            ref.current.style.transitionDuration = ref.current.dataset.duration
          }
        }
      },
      {
        rootMargin,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    } else {
      observer.observe(ref)
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      } else {
        observer.unobserve(ref)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export default useOnScreen
